<template>
  <div>
    <section
      id="contentProfile"
      style="background: #f2f2f2"
      class="min-h-screen pt-0 lg:pt-8 pb-8 flex flex-col gap-y-8"
    >
      <header
        id="headerNavs"
        class="block lg:hidden bg-white z-4 w-full border-0"
        v-if="!loading"
      >
        <div id="top" class="flex justify-between py-2 px-2">
          <span
            class="cursor-pointer"
            @click="$router.push({ name: 'doctorsList' })"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 18l-6-6 6-6"
              />
            </svg>
          </span>
          <span class="flex-1 font-EffraM text-base text-black text-center">
            {{ `${user.title} ${user.firstName} ${user.lastName}` }}</span
          >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 6.667c1.38 0 2.5-1.12 2.5-2.5 0-1.381-1.12-2.5-2.5-2.5s-2.5 1.119-2.5 2.5c0 1.38 1.12 2.5 2.5 2.5zM5 12.5c1.38 0 2.5-1.12 2.5-2.5S6.38 7.5 5 7.5 2.5 8.62 2.5 10s1.12 2.5 2.5 2.5zM15 18.333c1.38 0 2.5-1.119 2.5-2.5 0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5c0 1.381 1.12 2.5 2.5 2.5zM7.158 11.258l5.692 3.317M12.841 5.425L7.159 8.742"
              />
            </svg>
          </span>
        </div>

        <div
          id="navs"
          class="
            flex flex-nowrap
            gap-x-5
            overflow-x-scroll
            scrolling-touch
            w-full
            flex-1
            px-2
          "
        >
          <a
            href="#navDoctor"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.doctor") }}
          </a>
          <a
            href="#navAppointment"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.appointment") }}
          </a>
          <a
            href="#navAddress"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.address") }}
          </a>
          <a
            href="#navTarifs"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.tarifs") }}
          </a>
          <a
            href="#navDiplomes"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.diplomes") }}
          </a>
          <a
            href="#navLangs"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.spoken_languages") }}
          </a>
          <a
            href="#navOpenTimes"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.open_hours") }}
          </a>
          <a
            href="#navParcours"
            class="
              py-4
              w-150
              text-center text-gray-500
              border-0
              focus:border-b-2
              border-solid
              whitespace-no-wrap
              hover:text-rosDok-ultra hover:border-rosDok-ultra
              focus:border-rosDok-ultra focus:text-rosDok-ultra
            "
          >
            {{ $t("patient.parcours_professionnel") }}
          </a>
        </div>
      </header>

      <template v-if="!loading">
        <div class="flex flex-row justify-between items-center px-2 lg:px-12">
          <dok-nav class="invisible md:visible" :user="user" v-if="user._id" />

          <!-- <dok-button
            size="md"
            bg="blue"
            custom-class="font-EffraM flex items-center gap-x-3"
            @click.native="
              () => {
                $router.push({ name: 'settings' });
              }
            "
          >
            <i class="fas fa-chevron-left text-white text-2xl"></i>
            Back to profile</dok-button
          > -->
        </div>

        <div class="flex flex-row gap-x-8 px-2 lg:px-12">
          <section id="left" class="flex flex-col gap-y-3 w-full lg:w-3/44">
            <!-- Doctor Information -->
            <section
              id="navDoctor"
              class="
                bg-white
                w-full
                rounded
                p-2
                border border-solid
                flex flex-row
                gap-x-6
              "
              style="border-color: #e4e4e4"
            >
              <img
                :src="$checkAvatar(user.image)"
                onerror="javascript:this.src='/no-avatar.png'"
                :alt="`${user.title} ${user.firstName} ${user.lastName}`"
                class="object-cover border border-solid"
                style="
                  width: 172px;
                  height: 172px;
                  border-radius: 8px;
                  border-color: #e4e4e4;
                "
              />
              <div class="flex flex-col gap-y-6">
                <h1 class="text-2xl font-EffraM text-black">
                  {{
                    `${user.title === "DR" ? "Dr." : "Pr." || "Dr."} ${
                      user.firstName
                    } ${user.lastName}`
                  }}
                </h1>
                <div
                  class="grid gap-1 grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-4"
                >
                  <div class="flex flex-row items-start gap-x-3">
                    <i class="fas fa-border-none mt-1"></i>
                    <div class="flex flex-col gap-y-0">
                      <ul
                        v-if="
                          user.landingPage &&
                          user.landingPage.specialities &&
                          user.landingPage.specialities.length
                        "
                        class="font-EffraM text-black"
                      >
                        <li
                          v-for="(spec, index) in user.landingPage.specialities"
                          :key="index"
                        >
                          {{ spec.name }}
                        </li>
                      </ul>
                      <template v-if="!user.landingPage.specialities.length"
                        >--</template
                      >
                      <span class="font-EffraR" style="color: #767676">
                        {{ $t("patient.specialites") }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-row items-start gap-x-3">
                    <i class="fas fa-phone-alt"></i>
                    <div class="flex flex-col gap-y-0">
                      <span class="font-EffraM text-black">
                        {{ user.fix || "--" }}
                      </span>
                      <span class="font-EffraR" style="color: #767676">
                        {{ $t("patient.phone") }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-row items-start gap-x-3">
                    <i class="far fa-clock"></i>
                    <div class="flex flex-col gap-y-0">
                      <span class="font-EffraM text-black">
                        {{
                          user.landingPage &&
                          user.landingPage.experienceStartedSince
                            ? parseExperience(
                                user.landingPage.experienceStartedSince
                              ) +
                              " " +
                              $t("patient.years")
                            : "--"
                        }}
                      </span>
                      <span class="font-EffraR" style="color: #767676">
                        {{ $t("patient.experience") }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-row items-start gap-x-3">
                    <i class="fas fa-map-marker-alt"></i>
                    <div class="flex flex-col gap-y-0">
                      <span class="font-EffraM text-black">
                        {{
                          user.landingPage &&
                          user.landingPage.address &&
                          user.landingPage.address.city
                            ? user.landingPage.address.city.name
                            : "--"
                        }}
                      </span>
                      <span class="font-EffraR" style="color: #767676">
                        {{ $t("patient.ville") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Parcours  Professionnel &  Diplomes-->
            <section
              id="parcoursDiplomes"
              class="
                bg-white
                w-full
                rounded
                border border-solid
                flex flex-col
                gap-y-6
                py-6
              "
              style="border-color: #e4e4e4"
            >
              <div
                class="
                  grid
                  gap-x-4
                  grid-cols-1
                  lg:grid-cols-2
                  gap-y-3
                  lg:gap-y-0
                "
              >
                <div id="navParcours">
                  <div class="flex flex-col gap-y-6">
                    <h1
                      class="
                        text-gray-600
                        font-EffraM
                        text-base
                        flex
                        items-center
                        gap-x-1
                        px-6
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="#767676"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 5.25H3c-.828 0-1.5.672-1.5 1.5v7.5c0 .828.672 1.5 1.5 1.5h12c.828 0 1.5-.672 1.5-1.5v-7.5c0-.828-.672-1.5-1.5-1.5z"
                        />
                        <path
                          stroke="#767676"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 15.75v-12c0-.398-.158-.78-.44-1.06-.28-.282-.662-.44-1.06-.44h-3c-.398 0-.78.158-1.06.44-.282.28-.44.662-.44 1.06v12"
                        />
                      </svg>
                      {{ $t("patient.parcours_professionnel") }}
                    </h1>
                    <p
                      class="
                        text-black
                        font-EffraM
                        text-base
                        px-6
                        break-words
                        whitespace-pre-line
                      "
                    >
                      {{ user.doctorProfile.experienceBackground || "--" }}
                    </p>
                  </div>
                </div>
                <div id="navDiplomes">
                  <div class="flex flex-col gap-y-6">
                    <h1
                      class="
                        text-gray-600
                        font-EffraM
                        text-base
                        flex
                        items-center
                        gap-x-1
                        px-6
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="#767676"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 14.625c0-.497.198-.974.55-1.326.35-.351.828-.549 1.325-.549H15"
                        />
                        <path
                          stroke="#767676"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4.875 1.5H15v15H4.875c-.497 0-.974-.198-1.326-.55C3.198 15.6 3 15.123 3 14.626V3.375c0-.497.198-.974.55-1.326.35-.351.828-.549 1.325-.549v0z"
                        />
                      </svg>
                      {{ $t("patient.diplomes") }}
                    </h1>
                    <p
                      class="
                        text-black
                        font-EffraM
                        text-base
                        px-6
                        break-words
                        whitespace-pre-line
                      "
                      v-html="user.doctorProfile.experienceDiplomes || '--'"
                    ></p>
                  </div>
                </div>
              </div>
            </section>

            <!-- actes-->
            <!-- <section
              id="navLangs"
              class="
                bg-white
                w-full
                rounded
                border border-solid
                flex flex-col
                gap-y-6
                py-6
              "
              style="border-color: #e4e4e4"
            >
              <h1
                class="
                  text-gray-600
                  font-EffraM
                  text-base
                  flex
                  items-center
                  gap-x-1
                  px-6
                "
              >
                <i class="fas fa-notes-medical"></i>
                {{ $t("patient.actes") }}
              </h1>
              <div class="px-6 font-EffraM text-black text-base">
                <div v-if="!user.landingPage || !user.landingPage.acts.length">
                  --
                </div>
                <div v-else class="flex flex-wrap gap-y-2 gap-x-2">
                  <span
                    v-for="(item, index) in user.landingPage.acts"
                    :key="index"
                    class="
                      py-1
                      px-2
                      border border-solid border-rosDok-ultra
                      rounded-full
                      cursor-pointer
                      hover:bg-gray-100
                    "
                    @click="
                      $router.push(
                        `/medecin/acte/${item.slug}/${$route.params.city}`
                      )
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </div>
            </section> -->

            <!-- Languages-->
            <section
              id="navLangs"
              class="
                bg-white
                w-full
                rounded
                border border-solid
                flex flex-col
                gap-y-6
                py-6
              "
              style="border-color: #e4e4e4"
            >
              <h1
                class="
                  text-gray-600
                  font-EffraM
                  text-base
                  flex
                  items-center
                  gap-x-1
                  px-6
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="#767676"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 11.25s.75-.75 3-.75S9.75 12 12 12s3-.75 3-.75v-9S14.25 3 12 3 8.25 1.5 6 1.5s-3 .75-3 .75v9zM3 16.5v-5.25"
                  />
                </svg>
                {{ $t("patient.lang_talk") }}
              </h1>
              <p class="px-6 font-EffraM text-black text-base">
                {{
                  user.landingPage && user.landingPage.languages.length
                    ? user.landingPage.languages.join(", ")
                    : "--"
                }}
              </p>
            </section>

            <!-- Tarifs -->
            <section
              id="navTarifs"
              class="
                bg-white
                w-full
                rounded
                pt-3
                border border-solid
                flex flex-col
                gap-y-6
                py-6
              "
              style="border-color: #e4e4e4"
              v-if="tarifs.length"
            >
              <h1
                class="
                  text-gray-600
                  font-EffraM
                  text-base
                  flex
                  items-center
                  gap-x-1
                  px-6
                "
              >
                <i class="fas fa-coins"></i> {{ $t("patient.tarifs") }}
              </h1>

              <div class="flex flex-col">
                <div
                  class="
                    flex
                    justify-between
                    items-center
                    w-full
                    h-10
                    tarif-item
                  "
                  v-for="tarif in tarifs"
                  :key="tarif._id"
                  :style="`padding: 12px 23px;`"
                >
                  <span class="font-EffraM text-black text-lg">
                    {{ tarif.title }}
                  </span>
                  <span class="font-EffraM text-black text-lg">{{
                    tarif.price ? `${tarif.price} DH` : $t("patient.free")
                  }}</span>
                </div>
              </div>

              <div class="font-EffraR px-6 text-black">
                {{ $t("patient.tarif_description") }}
              </div>
            </section>

            <!-- Hours Work -->
            <section
              id="navOpenTimes"
              class="
                bg-white
                w-full
                rounded
                border border-solid
                flex flex-col
                gap-y-6
                py-6
              "
              style="border-color: #e4e4e4"
            >
              <h1
                class="
                  text-gray-600
                  font-EffraM
                  text-base
                  flex
                  items-center
                  gap-x-1
                  px-6
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="#767676"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 16.5c4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5z"
                  />
                  <path
                    stroke="#767676"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 4.5V9l3 1.5"
                  />
                </svg>

                {{ $t("patient.open_hours") }}
              </h1>

              <div class="grid gap-x-3 gap-y-2 grid-cols-1 lg:grid-cols-7 px-6">
                <div
                  v-for="(day, index) in generationDayInWeek()"
                  :key="index"
                  class="flex flex-col gap-y-3"
                >
                  <span class="font-EffraM text-black text-lg capitalize">
                    {{ day.label }}
                  </span>
                  <p v-if="slots.length" class="flex flex-col gap-y-1">
                    <span
                      v-for="(slot, index) in getSlotsBySpecificDay(day.label)
                        .slots"
                      :key="index"
                      class="font-EffraM text-base"
                      style="color: #5e5e5e"
                      >{{ slot.from }} - {{ slot.to }}</span
                    >
                  </p>
                </div>
              </div>
            </section>

            <!-- Doctor Map & Take Appointments Mobile & Tablet -->
            <div
              class="
                grid
                gap-x-1 gap-y-3
                md:gap-y-0
                grid-cols-1
                md:grid-cols-2
                lg:grid-cols-1
              "
            >
              <!-- Doctor Map -->
              <section
                id="navAddress"
                class="
                  order-2
                  md:order-1
                  bg-white
                  w-full
                  rounded
                  pt-3
                  border border-solid
                  flex flex-col
                  gap-y-12
                "
                style="border-color: #e4e4e4"
              >
                <div class="px-5 w-full flex justify-between items-center">
                  <div class="flex flex-row gap-x-2">
                    <i
                      class="fas fa-map-marker-alt mt-1"
                      style="color: #6d6d6d"
                    ></i>
                    <div class="flex flex-col gap-y-1">
                      <span
                        class="font-EffraM text-base"
                        style="color: #6d6d6d"
                        >{{ $t("patient.address") }}</span
                      >
                      <span class="font-EffraM text-base text-black">
                        {{ user.landingPage.address.label || "--" }}

                        {{
                          user.landingPage.address2
                            ? `(${user.landingPage.address2})`
                            : "--"
                        }}
                      </span>
                    </div>
                  </div>
                  <!-- <i
                    class="fas fa-share-alt text-2xl cursor-pointer hover:text-dokBlue-dark"
                  ></i> -->
                </div>
                <dok-map
                  v-if="user.landingPage && user.landingPage.address"
                  style="height: 273px"
                  :lat="parseFloat(user.landingPage.address.lat)"
                  :lng="parseFloat(user.landingPage.address.lng)"
                  :zoom="7"
                  :dragMark="false"
                  :title="`${user.firstName} ${user.lastName}`"
                />
              </section>

              <!-- Take Appointments -->
              <section
                id="navAppointment"
                class="
                  order-1
                  md:order-2
                  bg-white
                  w-full
                  rounded
                  py-3
                  border border-solid
                  flex flex-col
                  lg:hidden
                  gap-y-5
                "
                style="border-color: #e4e4e4"
              >
                <div class="px-6">
                  <available-hours
                    :doctorInfo="{
                      _id: user._id,
                      fullName: `${user.firstName} ${user.lastName}`,
                      address: user.landingPage.address,
                      slug: user.slug,
                    }"
                    :doctorId="user._id"
                    :tarifs="tarifs"
                  />
                </div>
              </section>
            </div>
            <!-- FAQ -->
            <section
              class="
                bg-white
                w-full
                rounded
                pt-3
                flex flex-col
                gap-y-6
                py-6
                border border-solid
              "
              style="border-color: #e4e4e4"
            >
              <h1
                class="
                  text-gray-600
                  font-EffraM
                  text-base
                  flex
                  items-center
                  gap-x-1
                  px-6
                "
              >
                <i class="fas fa-question"></i> FAQ
              </h1>
              <div
                v-for="(qs, indexqs) in questions"
                :key="indexqs"
                class="bg-white px-6"
              >
                <div class="flex flex-row justify-between gap-x-1">
                  <h3 class="text-lg font-EffraM" v-html="qs.qs" />
                  <svg
                    @click="openQuestion(indexqs)"
                    class="cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 9l6 6 6-6"
                    />
                  </svg>
                </div>
                <div v-if="slots.length && choiceIndex === 0 && indexqs === 0">
                  <a :href="qs.an" target="_blank"
                    ><i class="fas fa-search-location"></i>
                    <span
                      class="font-EffraM text-base text-gray-500 capitalize"
                    >
                      l'adresse de {{ user.title }} {{ user.firstName }}
                      {{ user.lastName }} est
                      {{
                        user.landingPage &&
                        user.landingPage.address &&
                        user.landingPage.address.city
                          ? [
                              user.landingPage.address.label,
                              user.landingPage.address2,
                              user.landingPage.address.city.name,
                            ]
                              .filter((i) => i)
                              .join(", ")
                          : "--"
                      }}
                    </span>
                  </a>
                </div>
                <div v-if="slots.length && choiceIndex === 1 && indexqs === 1">
                  <span class="font-EffraM text-base text-gray-500 capitalize">
                    {{ user.title }} {{ user.firstName }}
                    {{ user.lastName }} est ouvert:
                  </span>

                  <ul>
                    <li
                      v-for="(day, index) in generationDayInWeek()"
                      :key="index"
                    >
                      <span class="font-EffraM text-base capitalize">
                        - {{ day.label }} </span
                      >:
                      <span
                        v-if="getSlotsBySpecificDayFaq(day.label)"
                        class="font-EffraM text-base text-gray-500 capitalize"
                      >
                        {{ getSlotsBySpecificDayFaq(day.label) }}</span
                      >
                    </li>
                  </ul>
                </div>
                <p
                  v-if="choiceIndex === indexqs && choiceIndex !== 0"
                  class="leading-loose font-EffraM text-base text-gray-500"
                  v-html="qs.an"
                ></p>
              </div>
            </section>
          </section>
          <section
            id="right"
            class="hidden lg:flex flex-col gap-y-3 w-full lg:w-1/8377"
          >
            <section id="takeAppointment" class="flex flex-col gap-y-6 w-full">
              <section
                id="doctorMap"
                class="
                  bg-white
                  w-full
                  rounded
                  py-3
                  border border-solid
                  flex flex-col
                  gap-y-5
                "
                style="border-color: #e4e4e4"
              >
                <div class="px-6" v-if="user._id">
                  <available-hours
                    :doctorId="user._id"
                    :tarifs="tarifs"
                    :doctorInfo="{
                      _id: user._id,
                      fullName: `${user.firstName} ${user.lastName}`,
                      address: user.landingPage.address,
                      slug: user.slug,
                    }"
                  />
                </div>
              </section>
            </section>
            <section id="downloadApp" class="download-app">
              <dok-download-app
                :delete-classes="true"
                :other-text="true"
                otherClasses="p-0 m-0"
              />
            </section>
          </section>
        </div>

        <dok-nav class="px-2 lg:px-12" :user="user" v-if="user._id" />
      </template>

      <template v-if="loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="
            margin: auto;
            background: rgb(241, 242, 243);
            display: block;
            shape-rendering: auto;
          "
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </section>

    <footerLayout :downLoadApp="true" />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment";
  import { find, startCase, get } from "lodash";
  import debounce from "lodash/debounce";

  // Components
  const availableHours = () => import("./layouts/availableHours.vue");
  const footerLayout = () => import("@/views/home/layouts/footer.vue");
  const dokMap = () => import("@/views/global-components/map/index.vue");
  const dokDownloadApp = () =>
    import("@/layouts/home/layouts/navbar/layouts/downloadApp.vue");
  const dokNav = () => import("./layouts/nav");

  export default {
    data() {
      return {
        choiceIndex: -1,
        questions: [
          {
            qs: "Quelle est l'adresse de {{doctorName}} ?",
            key: "address",
          },
          {
            qs: "Quelles sont les horaires d'ouverture de {{doctorName}} ?",
            key: "working-hours",
          },
          {
            qs: "Quels sont les moyens de paiement acceptés par {{doctorName}} ?",
            key: "payment-methods",
          },
          {
            qs: "Est-ce que {{doctorName}} accepte des nouveaux patients ?",
            an: "Oui, {{doctorName}} accepte des nouveaux patients",
            key: "new-patients",
          },
          {
            qs: "Est-ce que {{doctorName}} propose la téléconsultation et pour quels motifs ?",
            key: "teleconsultation",
          },
          {
            qs: "Quel est le numéro de telephone de {{doctorName}} ?",
            key: "phone-number",
          },
        ],
        user: {},
        tarifs: [],
        slots: [],
        loading: true,
      };
    },
    components: { footerLayout, dokMap, dokNav, dokDownloadApp, availableHours },
    updated: debounce(function () {
      this.$nextTick(() => {
        window.addEventListener("scroll", this.scrollNavs);
      });
    }, 250),
    destroyed() {
      window.removeEventListener("scroll", this.scrollNavs);
    },
    created() {
      this.$store.dispatch("doctor/GET_BY_ID", {
        onData: this.onData,
        id: this.$route.params.slug,
      });

      this.$store.dispatch("agenda/GET_CATEGORIES", {
        onData: this.onCategories,
        data: { owner: this.$route.params.slug },
      });
    },
    methods: {
      ...mapActions("client", ["SET_CONVERSATION"]),
      ...mapActions("agenda", ["GET_SLOTS_BY_DOCTOR"]),
      generateFaq() {
        let faq = [];
        const doctorName = startCase(
          `${this.user.title} ${this.user.firstName} ${this.user.lastName}`.toLowerCase()
        );
        this.questions.forEach((question) => {
          question.qs = question.qs.replace("{{doctorName}}", doctorName);
          if (question.key === "payment-methods") {
            question.an = `${doctorName} accepte les moyens de paiements suivants: Chèques, espèces et cartes bancaires`;
          }
          if (question.key === "phone-number") {
            question.an = `Le numero de telephone de ${doctorName} est le : ${
              this.user.mobilePro || this.user.fix
            }`;
          }
          if (question.key === "teleconsultation") {
            question.an = `Oui ${doctorName} propose la téléconsultation pour les motifs suivants:`;
            const teleconsultations = this.tarifs.filter((item) => item.isVideo);
            if (teleconsultations.length > 0) {
              question.an += `<ul>`;
              teleconsultations.forEach((motif) => {
                question.an += `<li>-${motif.title}</li>`;
              });
              question.an += `</ul>`;
            } else {
              question.an = "{{doctorName}} ne propose pas de téléconsultation.";
            }
          }
          if (question.key === "address") {
            const lat = get(this.user, "landingPage.address.lat");
            const lng = get(this.user, "landingPage.address.lng");
            const mapUrl = `http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`;

            question.an = mapUrl;
          }
          if (question.an) {
            question.an = question.an.replace("{{doctorName}}", doctorName);
          }

          faq.push(question);
        });
        this.questions = faq;
      },

      openQuestion(index) {
        this.choiceIndex = index;
      },
      _() {
        return _;
      },
      scrollNavs(event) {
        let nav = document.getElementById("headerNavs");
        if (window.scrollY >= 79) {
          if (!nav.classList.contains("fixed")) {
            nav.classList.add("fixed");
            nav.classList.add("top-0");
          }
        } else {
          nav.classList.remove("fixed");
          nav.classList.remove("top-0");
        }
      },
      onCategories({ ok, data }) {
        if (ok) {
          this.tarifs = data;
        }
      },
      onData({ ok, data }) {
        if (!ok) {
          return this.$router.push("/");
        }

        if (ok) {
          this.user = data;
          this.generateFaq();
          this.loading = false;

          this.GET_SLOTS_BY_DOCTOR({
            id: data._id,
            onData: ({ ok, data }) => {
              if (ok) {
                this.slots = data;
              }
            },
          });
        }
      },
      generationDayInWeek() {
        let days = [];
        for (let i = 0; i <= 6; i++) {
          days.push({
            date: moment(moment().startOf("day"))
              .day("Lundi")
              .add(i, "days")
              .format("DD/MM/YYYY"),
            label: moment(moment().startOf("day"))
              .day("Lundi")
              .add(i, "days")
              .format("dddd"),
          });
        }
        return days;
      },
      getSlotsBySpecificDay(day) {
        let dayIndex = moment(day, "dddd").isoWeekday();

        let slots = find(this.slots, function (slot) {
          return slot.day === dayIndex;
        });

        return slots;
      },
      getSlotsBySpecificDayFaq(day) {
        let dayIndex = moment(day, "dddd").isoWeekday();

        let slot = find(this.slots, function (slot) {
          return slot.day === dayIndex;
        });
        if (!slot || !slot.slots.length) {
          return "---";
        }
        const formated = slot.slots
          .filter((slot) => {
            return slot.from && slot.to;
          })
          .map((slot) => {
            return [slot.from, slot.to].join(" - ");
          })
          .join(", ");
        return formated;
      },
      parseExperience(date) {
        return moment().diff(date, "years", false);
      },
    },
  };
</script>

<style lang="scss" scoped>
.download-app {
  background: url("/artwork/bg_down.png") #fff;
  padding: 16px 16px 16px;
  object-fit: contain;
}

.tarif-item {
  background-color: #fff;
}

.tarif-item:nth-child(2n + 1) {
  background-color: #f9f9f9;
}
</style>
